#paper-policy * {
    font-size: 10pt;
}
#paper-policy h1 {
    font-size: 25pt;
}
#paper-policy table:not(.table) th, #paper-policy table:not(.table) td {
    padding: 0.02cm 0.1cm;
    font-size: 10pt;
    border: 1px solid #233840;
}
#paper-policy table.table th {
    padding: 0 !important;
}
#paper-policy tbody tr td span {
    display: block;
    height: 100%;
    padding: .6mm 1mm;
}
#paper-policy tbody tr:nth-of-type(odd) td span {
    background-color: #e8f5da;
}
#paper-policy hr {
    border-top: 1px solid #233840;
}
@media print {
    @page {
        size: letter portrait;
        margin: 0 2cm;
    }
    #paper-policy {
        background-color: white;
    }
}