#paper * {
    font-size: 0.38805555555556cm;
}
#paper h1 {
    font-size: 25pt;
}
#paper table:not(.table) th, #paper table:not(.table) td {
    padding: 0.02cm 0.1cm;
    font-size: 10pt;
    border: 1px solid #233840;
}
#paper hr {
    border-top: 1px solid #233840;
}
@media print {
    @page {
        size: letter portrait;
        margin: 0 2cm;
    }
    #paper {
        background-color: white;
    }
    div.saltopagina{
        page-break-before:always;
    }
}