#header {
    height:50px;
    top: 0;
    height: 50px;
    position: fixed;
    z-index: 1000;
    width: 100%;
}

.offline #header {
    top: 50px;
}

#brand {
    z-index: 1;
    max-width: 230px;
    width: 100%;
}
@media screen and (min-width: 992px) {
    #sub-header {
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    }
}
@media screen and (max-width: 991px) {
    #brand {
        max-width: 260px;
    }
    #header {
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    }
}

@media screen and (max-width: 767px) {
    #brand {
        max-width: auto;
        width: auto;
        background-color: transparent;
    }
}

@media screen and (max-width: 500px) {
    #brand {
        background-color: transparent !important;
        max-width: 150px;
    }
}