#main {
    background-color: #f7f7f7;
    background-image: url(./assets/static/bg.png);
    background-size: contain;
    background-position: center;
    background-repeat: repeat;

    
}

#main #sidebar ~ div.col {
    margin-top: 50px;
}

@media screen and (min-width: 992px) {
    #main #sidebar ~ div.col {
        
        padding-left: calc(230px + 1rem);
    }
}