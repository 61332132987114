#table-pdf.print {
    width: 297mm;
    padding: 1cm;
}
#table-pdf.print .no-show-pdf {
    display: none;
}
#table-pdf h6 {
    display: none;
}
#table-pdf.print h6 {
    display: block;
}