

@media screen and (max-width: 767px) {
    table#table-partner-list thead tr th,
    table#table-partner-list tbody tr td {
        display: none;
    }
    table#table-partner-list thead tr th:nth-child(1),
    table#table-partner-list thead tr th:nth-child(2),
    table#table-partner-list thead tr th:nth-child(3),
    table#table-partner-list tbody tr td:nth-child(1),
    table#table-partner-list tbody tr td:nth-child(2),
    table#table-partner-list tbody tr td:nth-child(3),
    table#table-partner-list thead tr th:last-child,
    table#table-partner-list tbody tr td:last-child {
        display: table-cell;
    }
}