ul#navigation-steps a.active {
    background-color: #C93368;
    color: white;
    border: 0;
}

#nav-tab-clients a.active {
    background: transparent;
    border-bottom: 2px solid !important;
    
}

.nav-tab-data a.active {
    background-color: white !important;
}

#nav-tab-clients a:hover, .nav-tab-data a:hover {
    background-color: transparent !important;
    background: transparent !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
}

.nav-tab-data-client a.active,
.nav-tab-data-client a:hover {
    background-color: white !important;
}