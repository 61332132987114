@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

html, body, #root {
  min-height: 100vh;
}
body {
  font-family: 'Roboto', sans-serif;
  background-color: white;
}

/* http://webkit-scroll-gen.sourceforge.net/ */
html body #sidebar ::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
html body #sidebar ::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
html body #sidebar ::-webkit-scrollbar-thumb {
  background: #c788b2;
  border: 0px none #ffffff;
  border-radius: 50px;
}
html body #sidebar ::-webkit-scrollbar-thumb:hover {
  background: #c788b2;
}
html body #sidebar ::-webkit-scrollbar-thumb:active {
  background: #9e5985;
}
html body #sidebar ::-webkit-scrollbar-track {
  background: #5f0b46;
  border: 0px none #ffffff;
  border-radius: 50px;
}
html body #sidebar ::-webkit-scrollbar-track:hover {
  background: #5f0b43;
}
html body #sidebar ::-webkit-scrollbar-track:active {
  background: #5f0b43;
}
html body #sidebar ::-webkit-scrollbar-corner {
  background: transparent;
}

.tbody-multiple {
  border-top: 0 !important;
}

@media screen and (max-width: 991px) {
  .td-responsive-text {
    white-space: nowrap;
  }

  h3~div.card,
  .solfin-card {
    margin-left: -1rem;
    margin-right: -1rem;
    border-radius: 0;
    border: 0;
  }
}

#table-header-fixed {
  z-index: 1;
}
#table-header-fixed.position-fixed {
  top: 49px;
}
#puntero1 {
  position: absolute;
  top: -50px;
  width: 0;
  height: 0;
  z-index: -99999999;
}

.alert-wrapper {
  position: fixed;
  bottom: 0;
  z-index: 2;
  right: 0;
  left: 0;
}

.list-partner-item-counter{
  position: absolute;
  top: -13px;
  padding: 7px;
  min-width: 26px;
  min-height: 26px;
  border-radius: 20px;
  line-height: 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.minheightcard {
  min-height: 180px;
}