#paper-grupal-promissory-note * {
    font-size: 9pt;
}
#paper-grupal-promissory-note h1 {
    font-size: 25pt;
}
#paper-grupal-promissory-note table:not(.table) th, #paper-grupal-promissory-note table:not(.table) td {
    padding: 0.02cm 0.1cm;
    font-size: 10pt;
    border: 1px solid #233840;
}
#paper-grupal-promissory-note table th,
#paper-grupal-promissory-note table td {
    padding: .5mm 0.75rem;
}
#paper-grupal-promissory-note hr,
#paper-individual-promissory-note hr {
    border-top: 1px solid #233840;
}
@media print {
    @page {
        size: letter portrait;
        margin: 0 2cm;
    }
    #paper-grupal-promissory-note,
    #paper-individual-promissory-note {
        background-color: white;
    }
}