#sidebar {
    width: 230px;
    position: fixed;
    top: 50px;
    z-index: 1;
    height: calc(100% - 50px);
    transition: .3s cubic-bezier(0, 0, 0.2, 1);
    -webkit-transition: .3s cubic-bezier(0, 0, 0.2, 1);
    -moz-transition: .3s cubic-bezier(0, 0, 0.2, 1);
    -ms-transition: .3s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: .3s cubic-bezier(0, 0, 0.2, 1);
}

#sidebar #nav {
    overflow-y: scroll;
}

#sidebar #nav * {
    transition: .1s;
}
#sidebar #nav .nav-link.active,
#sidebar #nav .nav-link:hover,
#sidebar #nav .nav-link.active + button,
#sidebar #nav .nav-link:hover + button {
    background-color: rgba(255, 255, 255, 0.13) !important;
}

#sidebar #nav > * {
    width: 100%;
}

@media screen and (max-width: 991px) {
    #sidebar {
        left: -235px;
        top: 65px;
        bottom: 15px;
        height: auto;
        border-radius: 12px;
        z-index: 1021;
    }

    #sidebar.show {
        left: 15px;
        box-shadow: 0px 3px 15px -5px black;
    }

    #btn-nav {
        position: fixed;
        right: 15px;
        bottom: 15px;
        z-index: 2;
    }

    #sidebar.show ~ #btn-nav:before {
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: auto;
        z-index: -1;
    }
}

@media screen and (min-width: 992px) {
    .offline #sidebar {
        top: 100px;
    }
    #sidebar {
        box-shadow: 0.5rem 0 1rem rgb(0 0 0 / 15%) !important;
    }
}